@import './../../assets/scss/reset/reset';
@import './../../assets/scss/utils/variables';

.response-container {
    padding: 40px 80px 0;
    margin-bottom: 20px;
    
    .response {
        padding: 158px 0;
        width: 100%;
        position: relative;
        background-color: $color-light-gray;
        display: flex;
        flex-direction: column;
        align-items: center;

        .pin-circle {
            width: 64px;
            height: 64px;
            background-color: $color-white;
            border-radius: 50%;
            position: absolute;
            top: 32px;
            left: -32px;
        
            &:nth-child(2) {
                top: unset;
                bottom: 32px;
            }
        
            &:nth-child(3) {
                left: unset;
                right: -32px;
            }
        
            &:nth-child(4) {
                top: unset;
                left: unset;
                bottom: 32px;
                right: -32px;
            }
        }
    
        .motto {
            font-family: RozhaOneRegular, sans-serif;
            font-style: normal;
            margin-bottom: 12px;
            font-weight: 110;
            font-size: 50px;
            line-height: 50px;
            text-align: center;
            color: $color-black;
        }  
        
        .message {
            font-family: PublicSans-Thin;
            margin-bottom: 12px;
            font-weight: 400;
            font-size: 50px;
            line-height: 50px;
            text-align: center;
            color: $color-black;
        } 
    
        .let {
            font-family: PublicSans-Regular;
            margin-bottom: 44px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            color: #9E9EAA;
        }
    
        .response-btn {
            background-color: $color-yellow;
            border: none;
            width: 152px;
            height: 152px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-family: PublicSans-Thin;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: $color-black;
            text-transform: uppercase;
            cursor: pointer;
            transition: 400ms all;
            text-align: center;
            transform: scale(1);
    
            &:hover {
                transform: scale(1.2);
                background-color: $color-black;
                color: $color-white;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .response-container {
        padding: 0;
        margin-bottom: -20px;
        
        .response {
    
            .pin-circle {
                width: 54px;
                height: 54px;
                top: 27px;
                left: -27px;
            
                &:nth-child(2) {
                    top: unset;
                    bottom: 27px;
                }
            
                &:nth-child(3) {
                    left: unset;
                    right: -27px;
                }
            
                &:nth-child(4) {
                    top: unset;
                    left: unset;
                    bottom: 27px;
                    right: -27px;
                }
            }
        
            .motto {
                font-size: 30px;
                line-height: 30px;
            }  
            
            .message {
                font-size: 30px;
                line-height: 30px;
            }
        
            .response-btn {
                width: 158px;
                height: 158px;
            }
        }
    }
}
