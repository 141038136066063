@import './../../../assets/scss/reset/reset';
@import './../../../assets/scss/utils/variables';

.textarea-container {
    display: flex;
    flex-direction: column;

    label {
        font-family: PublicSans-Medium;
        font-style: normal;
        font-weight: 510;
        font-size: 16px;
        line-height: 20px;
        color: $color-black;
        margin-bottom: 10px;
    }

    textarea {
        margin-bottom: 6px;
        border: 2px solid transparent;
        width: 100%;
        min-height: 156px;
        padding: 20px 24px;
        font-family: PublicSans-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $color-black;

        &::placeholder {
            font-family: PublicSans-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;
            color: $color-gray;
        }

        &.error {
            border-color:  rgba(242, 31, 107, 0.2);
        }

        &:focus {
            border: 2px solid rgba(158, 158, 170, 0.3);
        }
    }

    .error-message {
        font-family: PublicSans-Medium;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $color-danger;
        opacity: 0;
        visibility: hidden;
        transition: 300ms all;
        min-height: 20px;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .textarea-container {    
        textarea {
            min-height: 180px;
            padding: 20px;
        }
    
        .error-message {
            font-size: 14px;
        }
    }
}
