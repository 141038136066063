@import './../../../assets/scss/reset/reset.scss';
@import './../../../assets/scss/utils/variables.scss';

.works-header {
    padding: 32px 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .works-title {
        h1 {
            max-width: 900px;
            font-family: PublicSans-Thin;
            font-style: normal;
            font-weight: 110;
            font-size: 50px;
            line-height: 50px;
            color: $color-black;
            
            .bold {
                font-family: RozhaOneRegular, sans-serif;
                font-style: normal;
            }
        }
    }

    .select {
        min-width: 326px;
        background-color: $color-light-gray;
        border: 2px solid $color-light-gray;
        cursor: pointer;
        position: relative;

        &.active {
            background-color: $color-white;

            .selected {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .selected {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 20px;

            .text {
                font-family: PublicSans-Medium;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: $color-black;
                text-transform: uppercase;
            }

            svg {
                transition: 400ms all;
                transform: rotate(0);
            }
        }

        .options {
            max-height: 0;
            width: 100%;
            position: absolute;
            left: 0;
            z-index: 9999;
            top: calc(100% + 6px);
            padding: 0 20px;
            background-color: $color-white;
            border: 2px solid transparent;
            transition: 400ms all;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .option-text {
                    font-family: PublicSans-Medium;
                    color: $color-black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    text-transform: uppercase;
                }

                .check-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $color-light-gray;
                }

                &:first-child {
                    padding-top: 20px;
                }
    
                &:last-child {
                    border-top: 1px solid #F3F3F3;
                    margin-bottom: 16px;
                    padding-top: 16px;
                    font-family: PublicSans-Medium;
                    color: $color-gray;
                    text-transform: uppercase;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    text-decoration: underline;
                }
            }

            &.active {
                max-height: 500px;
                border: 2px solid $color-light-gray;
            }
        }
    }
}

.all-works {
    padding: 80px 80px 120px;
    transition: 400ms all;
    width: 100%;

    .all-works-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 60px;
        width: 100%;

        .work {
            width: calc(50% - 12px);
            position: relative;
            cursor: pointer;
            
            .aspect-ratio-container {
                width: 100%;
                padding-top: 115%;
                position: relative;

                a {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;

                    .cover-img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .name, .short-desc {
                        position: absolute;
                        left: 32px;
                    }
        
                    .name {
                        top: 32px;
                        font-family: RozhaOneRegular, sans-serif;
                        font-style: normal;
                        font-weight: 110;
                        font-size: 50px;
                        line-height: 52px;
                        color: $color-white;
                    }
        
                    .short-desc {
                        top: 96px;
                        max-width: 420px;
                        text-transform: uppercase;
                        font-family: PublicSans-Regular;
                        font-style: normal;
                        font-weight: 510;
                        font-size: 12px;
                        line-height: 14px;
                        color: $color-white;
                        text-indent: 36px;
                    }

                    .hover-img-container {
                        position: absolute;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        left: 50%;
                        overflow: hidden;
                        transition: 800ms all;
                        width: calc(100% - 64px);
                        padding-top: 59%;
                        opacity: 0;
                        visibility: hidden;

                        .hover-img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            object-fit: cover;
                            transition: 800ms all;
                            transform: scale(0.9);
                        }
                    }

                    &:hover {
                        .hover-img-container {
                            opacity: 1;
                            visibility: visible;

                            .hover-img {
                                transform: scale(1);
                            }
                        }
                    }
                }
            }

            .work-services {
                display: inline-block;
                margin-top: 16px;
                font-family: PublicSans-Medium;
                font-style: normal;
                font-weight: 510;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                color: $color-black;
            }
        }
    }

    .btn {
        display: flex;
        margin-top: 32px;
        justify-content: center;
    }
}

@media screen and (max-width: $breakpoint-md) {
    .works-header {
        padding: 32px 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 32px;
    
        .works-title {
            h1 {
                max-width: unset;
                font-size: 30px;
                line-height: 30px;
                text-align: center;
            }
        }
    
        .select {
            min-width: unset;
            width: 100%;
    
            .selected {
                padding: 12px 16px;
            }
        }
    }
    
    .all-works {
        padding: 32px 16px 60px;
    
        .all-works-container {
            column-gap: unset;
            row-gap: 60px;
            width: 100%;
    
            .work {
                width: 100%;
                
                .aspect-ratio-container {
                    padding-top: 136%;
    
                    a {
                        .name, .short-desc {
                            left: 16px;
                        }
            
                        .name {
                            top: 16px;
                            font-size: 30px;
                            line-height: 32px;
                        }
            
                        .short-desc {
                            top: 60px;
                            max-width: unset;
                            text-indent: 28px;
                            width: calc(100% - 32px);
                        }
                    }
                }
    
                .work-services {
                    line-height: 14px;
                }
            }
        }
    
        .btn {
            display: flex;
            margin-top: 32px;
            justify-content: center;
        }
    }
}
