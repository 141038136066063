@import './../../../assets/scss/reset/reset.scss';
@import './../../../assets/scss/utils/variables.scss';

.services-hero {
    padding: 0 80px;
    background-color: $color-yellow;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: calc(100vh - 143px);
    align-items: center;

    .hero-infos {
        width: 46%;

        h5 {
            font-family: PublicSans-Light;
            font-style: normal;
            font-weight: 510;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: $color-black;
            opacity: 0.5;
        }
    
        h1 {
            max-width: 900px;
            font-family: PublicSans-Thin;
            font-style: normal;
            font-weight: 110;
            font-size: 50px;
            line-height: 50px;
            color: $color-black;
            text-indent: 60px;
            margin-bottom: 40px;
            
            .bold {
                font-family: RozhaOneRegular, sans-serif;
                font-style: normal;
            }
        }
    
        p {
            max-width: 436px;
            font-style: normal;
            font-weight: 510;
            line-height: 20px;
            font-size: 16px;
            font-family: PublicSans-Light;
            color: $color-black;
    
            &:first-of-type {
                margin-bottom: 24px;
            }
        }
    }

    .hero-slider {
        height: 100%;
        width: 48%;
        position: relative;
        
        .service-marquee-container {
            display: flex;
            flex-direction: column;
            gap: 28px;
            width: 100%;
            position: absolute;
            height: max-content;
            top: 0;
            animation: marqueeTop 120s linear infinite;
        
            .marquee-item {                    
                width: 100%;
                position: relative;
                padding-top: 53%;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        @keyframes marqueeTop {
            0% {
                  top: 0;
              }
              100% {
                  top: -12318px;
              }
        }
    }

    .services-marquee-container {
        display: none;
    }
}

.services-creativity {
    padding: 120px 80px;

    h5 {
        font-family: PublicSans-Light, sans-serif;
        font-style: normal;
        font-weight: 510;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: $color-black;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(158, 158, 170, 0.3);
    }

    .sub-services {
        padding: 40px 40px 0 0;
        display: flex;
        justify-content: flex-end;
        gap: 171px;

        .sub-service {
            &-name {
                font-family: PublicSans-Medium, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                color: $color-black;
                margin-bottom: 12px;
                display: inline-block;
            }

            ul {
                li {
                    font-family: PublicSans-Light, sans-serif;
                    font-style: normal;
                    font-weight: 510;
                    font-size: 14px;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: $color-black;
                    text-transform: uppercase;
                    color: $color-black;
                }
            }
        }
    }
}

.our-services {
    padding: 120px 80px 100px;

    h5 {
        font-family: PublicSans-Light, sans-serif;
        font-style: normal;
        font-weight: 510;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: $color-black;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(158, 158, 170, 0.3);
    }

    .elements {
        .service {
            padding: 60px 0;
            display: flex;
            border-bottom: 1px solid rgba(158, 158, 170, 0.3);
            justify-content: space-between;

            &-info {
                padding-top: 20px;
                max-width: 654px;

                .service-name {
                    font-family: RozhaOneRegular, sans-serif;
                    font-style: normal;
                    font-weight: 110;
                    font-size: 50px;
                    line-height: 50px;
                    color: $color-black;
                    margin-bottom: 24px;
                    display: inline-block;
                }

                .service-desc {
                    font-style: normal;
                    font-weight: 510;
                    line-height: 20px;
                    font-size: 16px;
                    font-family: PublicSans-Light, sans-serif;
                    color: $color-black;
                    height: 114px;
                }

                .btn {
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    gap: 16px;
            
                    .hoverable-text {
                        transition: 300ms all;
                        font-family: PublicSans-Light;
                        text-transform: uppercase;
                        color: $color-black;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        height: 20px;
                        overflow: hidden;
            
                        .letters {
                            position: relative;
                            top: 20px;
                        }
                        
            
                        &.active {
                            .letters {
                                animation: wave1 300ms linear forwards;
                            }
                        }
                    }
                }
            }

            img {
                width: 48%;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.customers-slider {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: $color-black;
    position: relative;

    .customer {
        padding: 236px 0 236px 80px;
        
        p {
            font-family: PublicSans-Thin;
            margin-bottom: 52px;
            font-style: normal;
            font-weight: 110;
            font-size: 50px;
            line-height: 52px;
            color: $color-white;
            max-width: 980px;
        }

        div {
            display: flex;
            gap: 28px;
            align-items: center;

            div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;

                span {
                    font-family: PublicSans-SemiBold;
                    font-style: normal;
                    font-weight: 590;
                    font-size: 16px;
                    line-height: 18px;
                    color: $color-white;
                    text-transform: uppercase;

                    &:last-child {
                        font-family: PublicSans-Light;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        text-transform: uppercase;
                        color: $color-white;
                        max-width: 190px;
                        opacity: 0.5;
                    }
                }
            }
        }

        &.opacity-20 {
            opacity: 0.2 !important;
        }
    }

    .slick-track {
        display: flex !important;
    
        .slick-slide {
            overflow: hidden;
        }
    }
    
    .slick-dots {
        margin-bottom: 120px;
        padding: 0 80px !important;
        text-align: left !important;
    
        li {
            width: 12px !important;
            height: 12px !important;
    
            button {
                background-color: $color-white !important;
                border-radius: 50% !important;
                width: 12px !important;
                height: 12px !important;
    
                &::before {
                    color: transparent !important;
                    width: 12px !important;
                    height: 12px !important;
                }
            }
    
            &.slick-active {
                button {
                    background-color: $color-yellow !important;
                }
            }
        }
    }
}


@media screen and (max-width: $breakpoint-md) {
    .services-hero {
        padding: 48px 0 80px;
        height: unset;
        flex-direction: column;
        gap: 80px;
    
        .hero-infos {
            padding: 0 16px;
            width: 100%;
    
            h5 {
                font-size: 10px;
            }
        
            h1 {
                max-width: unset;
                font-size: 30px;
                line-height: 30px;
                text-indent: 50%;
            }
        
            p {
                max-width: unset;
            }
        }
    
        .hero-slider {
            height: 100%;
            width: 48%;
            position: relative;
            display: none;
            
            .service-marquee-container {
                display: flex;
                flex-direction: column;
                gap: 28px;
                width: 100%;
                position: absolute;
                height: max-content;
                top: 0;
                animation: marqueeTop 120s linear infinite;
            
                .marquee-item {                    
                    width: 100%;
                    position: relative;
                    padding-top: 53%;
    
                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
    
            @keyframes marqueeTop {
                0% {
                      top: 0;
                  }
                  100% {
                      top: -12318px;
                  }
            }
        }

        .services-marquee-container {
            display: block;
            width: 100%;

            .services-slide {
                height: 176px;
                width: 300px !important;
                overflow: hidden;
                margin-left: 14px;
        
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
        
            }
        }
    }
    
    .services-creativity {
        padding: 80px 16px;
    
        h5 {
            font-size: 12px;
        }
    
        .sub-services {
            padding: 36px 0 0;
            justify-content: unset;
            flex-direction: column;
            gap: 36px;
    
            .sub-service {    
                &-name {
                    font-size: 12px;
                }
                
                ul {
                    li {
                        font-family: PublicSans-Regular;
                        font-style: normal;
                        font-weight: 510;
                        font-size: 12px;
                        line-height: 16px;
                        text-transform: uppercase;
                        color: $color-black;
                    }
                }
            }
        }
    }
    
    .our-services {
        padding: 0 16px;
    
        h5 {
            font-size: 12px;
        }
    
        .elements {
            .service {
                padding: 0 0 32px;
                flex-direction: column;
                gap: 24px;
    
                &-info {
                    padding-top: 24px;
                    max-width: unset;
    
                    .service-name {
                        font-size: 30px;
                        line-height: 30px;
                        margin-bottom: 12px;
                    }
    
                    .service-desc {
                        height: unset;
                    }
    
                    .btn {
                        gap: 8px;
                
                        .hoverable-text {
                            font-size: 12px;
                            line-height: 14px;
                            height: 14px;
                
                            .letters {
                                top: 14px;
                            }
                        }
                    }
                }
    
                img {
                    width: 100%;
                }
    
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    
    .customers-slider {
        padding: 100px 0;
        height: unset;
    
        .customer {
            padding: 0 0 0 16px;
            
            p {
                font-size: 30px;
                line-height: 30px;
                max-width: unset;
            }
    
            div {
                gap: 16px;
    
                div {
                    gap: 4px;
    
                    span {
                        &:last-child {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    
        .slick-track {
            display: flex !important;
        
            .slick-slide {
                overflow: hidden;
            }
        }
        
        .slick-dots {
            bottom: -170px;
            padding: 0 16px !important;
        
            li {
                width: 8px !important;
                height: 8px !important;
        
                button {
                    width: 8px !important;
                    height: 8px !important;
        
                    &::before {
                        width: 8px !important;
                        height: 8px !important;
                    }
                }
            }
        }
    }
}
