@import './../../assets/scss/reset/reset';
@import './../../assets/scss/utils/variables';

.not-found {
    width: 100vw;
    height: 100vh;
    background-color: $color-light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .motto {
        font-family: RozhaOneRegular, sans-serif;
        font-style: normal;
        margin-bottom: 12px;
        font-weight: 110;
        font-size: 50px;
        line-height: 50px;
        text-align: center;
        color: $color-black;
    }  
    
    .message {
        font-family: PublicSans-Thin;
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 50px;
        line-height: 50px;
        text-align: center;
        color: $color-black;
    } 

    .let {
        font-family: PublicSans-Regular;
        margin-bottom: 44px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #9E9EAA;
    }

    .not-found-btn {
        background-color: $color-yellow;
        border: none;
        width: 152px;
        height: 152px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-family: PublicSans-Light;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: $color-black;
        text-transform: uppercase;
        cursor: pointer;
        transition: 400ms all;
        text-align: center;

        &:hover {
            width: 190px;
            height: 190px;
            background-color: $color-black;
            color: $color-white;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .not-found {    
        .motto {
            font-size: 30px;
            line-height: 30px;
        }  
        
        .message {
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 20px;
        } 
    
        .let {
            margin-bottom: 54px;
        }
    
        .not-found-btn {
            width: 158px;
            height: 158px;
            font-size: 12px;
            line-height: 14px;
        }
    }
}
